import React, { FunctionComponent, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import "./homePage.scss";
import logo from "../../../assets/img/logo.jpg";
import { GlobalContext } from "../../../contexts/GlobalProvider";

const HomePage: FunctionComponent = () => {
  const { getAllTools, fetchProjects, JWT } = useContext(GlobalContext);
  const history = useHistory();
  const navigate = (pageName: string) => {
    history.push(pageName);
  };

  useEffect(() => {
    if (JWT) {
      getAllTools();
      fetchProjects();
    }
  }, [JWT]);

  return (
    <div className="homeContainer">
      <img src={logo} alt="JetSupport logo" className="imageLogo" />
      <div className="homeRow">
        <div className="button" onClick={() => navigate("/taketool")}>
          <p className="buttonText">Take tool</p>
        </div>
        <div className="button" onClick={() => navigate("/returntool")}>
          <p className="buttonText">Return tool</p>
        </div>
      </div>
      <div className="homeRow">
        <div className="button" onClick={() => navigate("/searchtool")}>
          <p className="buttonText">Search tool</p>
        </div>
        <div className="button" onClick={() => navigate("/projects")}>
          <p className="buttonText">Projects</p>
        </div>
      </div>
      <div className="homeRow">
        <div className="button" onClick={() => navigate("/mytools")}>
          <p className="buttonText">My tools</p>
        </div>
        <div className="button" onClick={() => navigate("reportbug")}>
          <p className="buttonText">Report bug</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
