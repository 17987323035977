import React, { FunctionComponent, useEffect } from "react";
import { Technician } from "../../models/technician";
import InfoField from "../infoField/infoField";
import "./technicianCard.scss";

export type Props = {
  technician?: Technician;
  techScanFunction?: Function;
};

const TechnicianCard: FunctionComponent<Props> = ({ technician }) => {
  return (
    <div className="technicianCardContainer">
      <div className="technicianCardHeader">
        <p className="technicianCardHeaderText">Technician</p>
      </div>
      <InfoField
        title="Name"
        info={
          technician ? technician.sfirstName + " " + technician.slastName : ""
        }
      />
      <InfoField title="Trigram" info={technician ? technician.strigram : ""} />
    </div>
  );
};

export default TechnicianCard;
