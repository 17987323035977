import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import { GlobalContext } from "../../contexts/GlobalProvider";
import { WorkOrder } from "../../models/workorder";
import CollapsibleCard from "../collapsibleCard/collapsibleCard";
import InfoField from "../infoField/infoField";
import PopUp from "../popUp/popUp";
import WOItem from "../woItem/woItem";
import "./projectCard.scss";

export type Props = {
  workOrder?: WorkOrder;
};

const ProjectCard: FunctionComponent<Props> = ({ workOrder }) => {
  const {
    fetchTool,
    getCurrentTechnician,
    currentScannedTool,
    currentTechnician,
    takeTool,
    fetchOpenProjects,
    openProjects,
    setWorkOrder,
    currentWorkOrder,
  } = useContext(GlobalContext);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const togglePopUpVisible = () => {
    setPopUpVisible(!popUpVisible);
  };
  const pickProject = () => {
    togglePopUpVisible();
  };

  const selectWorkOrder = (wo: WorkOrder) => {
    setWorkOrder(wo);
    togglePopUpVisible();
  };
  const test = () => {};

  useEffect(() => {
    fetchOpenProjects();
  }, []);

  const generateProjectCards = () => {
    return (
      <div>
        {openProjects?.map((project) => (
          <CollapsibleCard title={project.saircraftReg}>
            {generateWorkOrderCard(project.workOrderList)}
          </CollapsibleCard>
        ))}
      </div>
    );
  };

  const generateWorkOrderCard = (workOrders: WorkOrder[]) => {
    return (
      <div>
        {workOrders.map((workOrder: WorkOrder) => (
          <div
            className="collapsibleCardTriggerHeader"
            onClick={() => selectWorkOrder(workOrder)}
          >
            <div className="left" />
            <p className="woText">{workOrder.sreference}</p>
            <div className="collapsibleCardCircleWO">
              <p className="collapsibleCardCircleTextWO">&#8250;</p>
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="projectCardContainer">
      <div className="projectCardHeader">
        <p className="projectCardHeaderText">Project</p>
        <div className="projectCircle" onClick={pickProject}>
          <p className="projectCircleText">&#43;</p>
        </div>
      </div>
      <InfoField
        title="Workorder number"
        info={workOrder ? workOrder.sreference : ""}
      />
      <InfoField
        title="Aircraft registration"
        info={workOrder ? workOrder.saircraftReg : ""}
      />
      <PopUp
        title="Pick project"
        visible={popUpVisible}
        setVisible={popUpVisible ? () => test() : () => togglePopUpVisible()}
      >
        {generateProjectCards()}
        <div className="closeButton" onClick={() => togglePopUpVisible()}>
          <div className="closeButtonText">Close</div>
        </div>
      </PopUp>
    </div>
  );
};

export default ProjectCard;
