import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
} from "react";
import { GlobalContext } from "../../../contexts/GlobalProvider";
import ButtonLayout from "../../buttonLayout/buttonLayout";
import { PageHeader } from "../../header/pageHeader";
import ProjectCard from "../../projectCard/projectCard";
import TechnicianCard from "../../TechnicianCard/technicianCard";
import ToolCard from "../../ToolCard/toolCard";
import { useHistory } from "react-router-dom";
import "./takeToolPage.scss";
import PopUp from "../../popUp/popUp";
import QrReader from "react-qr-reader";
import { createFalse } from "typescript";

const TakeToolPage: FunctionComponent = () => {
  const {
    fetchTool,
    getCurrentTechnician,
    currentScannedTool,
    currentTechnician,
    takeTool,
    currentWorkOrder,
    clearTool,
    allTools,
    getAllTools,
    emitErrorToast,
  } = useContext(GlobalContext);
  const history = useHistory();
  const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(false);
  const [techScannerOpen, setTechScannerOpen] = useState(false);
  const [toolScannerOpen, setToolScannerOpen] = useState(false);
  const [warningVisible, setWarningVisible] = useState(false);

  const scanTool = (qr?: string) => {
    if (qr) {
      fetchTool(qr.toUpperCase());
      setToolScannerOpen(false);
    }
  };

  const scanTech = (qr?: string) => {
    if (qr) {
      getCurrentTechnician(qr.toUpperCase());
      setTechScannerOpen(false);
      console.log(currentScannedTool);
    }
  };

  const toggleTechScannerOpen = () => {
    setTechScannerOpen(!techScannerOpen);
  };

  const toggleToolScannerOpen = () => {
    setToolScannerOpen(!toolScannerOpen);
  };

  useEffect(() => {
    if (
      (currentWorkOrder.saircraftReg.trim().length > 2) &&
      (currentScannedTool.sinternalReference.trim().length > 2) &&
      currentTechnician.idTechnician
    ) {
      setConfirmButtonEnabled(true);
      checkTool();
    } else {
      setConfirmButtonEnabled(false);
    }
    if (currentScannedTool) {
      if (currentScannedTool.counter > 2) {
        setWarningVisible(true);
      }
    }
  }, [currentWorkOrder, currentScannedTool, currentTechnician]);

  const takeCurrentTool = () => {
    takeTool(currentTechnician, currentScannedTool, currentWorkOrder);
    setConfirmButtonEnabled(false);
    setToolScannerOpen(true);
  };

  const generateWarning = () => {
    if (currentScannedTool) {
      if (currentScannedTool.counter > 2) {
        return (
          <PopUp
            title="Tool warning"
            visible={warningVisible}
            setVisible={() => setWarningVisible(false)}
          >
            <p className="redText">
              Tool has been taken out more then 3 times, please return to stores
            </p>
            <div
              className={"layoutConfirmButton layoutButton"}
              onClick={() => setWarningVisible(false)}
            >
              <p className="layoutButtonText">Confirm</p>
            </div>
          </PopUp>
        );
      }
    }
    return null;
  };

  const closeFunction = () => {
    setToolScannerOpen(false);
    setTechScannerOpen(false);
    clearTool();
    history.goBack();
  };

  const checkTool = () => {
    let found = false;
    let correct = false;

    if(allTools.length <= 0){
      setConfirmButtonEnabled(true);
      return;
    }

    allTools.forEach(element => {
      if(found) return;

      found = element.uiInventoryItem === currentScannedTool.uiInventoryItem;
      correct = element.uiInventoryItem === currentScannedTool.uiInventoryItem && element.strigram === currentTechnician.strigram && element.sreference === currentWorkOrder.sreference;
      correct = !correct;
    });
    if(correct){
      setConfirmButtonEnabled(true);
      return;
    } else {
      emitErrorToast('This tool is already taken by you on this project');
      setConfirmButtonEnabled(false);
    }
  }

  return (
    <div className="takeToolPageContainer">
      <PageHeader title="Take tool" />
      {techScannerOpen ? (
        <PopUp
          title="Scan a crew card"
          visible={techScannerOpen}
          setVisible={() => toggleTechScannerOpen()}
        >
          <QrReader
            delay={0}
            onError={() => console.log("scanner error")}
            onScan={(data) => scanTech(data?.toString())}
            style={{ width: "60%" }}
          />
        </PopUp>
      ) : null}
      {toolScannerOpen ? (
        <PopUp
          title="Scan a tool"
          visible={toolScannerOpen}
          setVisible={() => toggleToolScannerOpen()}
        >
          <QrReader
            delay={0}
            onError={() => console.log("scanner error")}
            onScan={(data) => scanTool(data?.toString())}
            style={{ width: "60%" }}
          />
        </PopUp>
      ) : null}
      {generateWarning()}
      <div className="takeToolContent">
        <TechnicianCard technician={currentTechnician} />
        <ProjectCard workOrder={currentWorkOrder} />
        <ToolCard tool={currentScannedTool} customTool={true} />
      </div>
      <ButtonLayout
        scanTech={true}
        confirmEnabled={confirmButtonEnabled}
        scanToolFunction={() => toggleToolScannerOpen()}
        scanTechFunction={() => toggleTechScannerOpen()}
        confirmFunction={() => takeCurrentTool()}
        backFunction={() => closeFunction()}
      />
    </div>
  );
};

export default TakeToolPage;
