import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import "./buttonLayout.scss";

type Props = {
  scanTech?: boolean;
  scanTool?: boolean;
  scanToolFunction?: Function;
  scanTechFunction?: Function;
  confirmEnabled: boolean;
  confirmFunction?: Function;
  backFunction?: Function;
};

const ButtonLayout: FunctionComponent<Props> = ({
  scanTech,
  scanTool,
  scanToolFunction,
  scanTechFunction,
  confirmEnabled,
  backFunction,
  confirmFunction,
}) => {
  const history = useHistory();
  const navigateBack = () => {
    history.goBack();
  };
  return (
    <div className="buttonLayoutContainer">
      <div className="buttonLayoutRow">
        <div
          className="layoutButton layoutCancelButton"
          onClick={backFunction ? () => backFunction() : navigateBack}
        >
          <p className="layoutButtonText">Close</p>
        </div>
        <div className="buttonLayoutRowMiddle">
          {scanTech ? (
            <div
              className="layoutButton layoutNormalButton"
              onClick={scanTechFunction ? () => scanTechFunction() : undefined}
            >
              <p className="layoutButtonText">Scan tech</p>
            </div>
          ) : null}
          {!scanTool ? (
            <div
              className="layoutButton layoutNormalButton"
              onClick={scanToolFunction ? () => scanToolFunction() : undefined}
            >
              <p className="layoutButtonText">Scan tool</p>
            </div>
          ) : null}
        </div>
        <div
          className={
            confirmEnabled
              ? "layoutConfirmButton layoutButton"
              : "layoutDisabledButton layoutButton"
          }
          onClick={
            confirmEnabled
              ? confirmFunction
                ? () => confirmFunction()
                : undefined
              : undefined
          }
        >
          <p className="layoutButtonText">Confirm</p>
        </div>
      </div>
    </div>
  );
};

export default ButtonLayout;
