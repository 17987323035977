import React, { FunctionComponent, useEffect } from "react";
import "./infoField.scss";

type Props = {
    title: string;
    info: string;
}

const InfoField: FunctionComponent<Props> = ({title, info}) => {
  return (
    <div className="infoFieldContainer">
      <p className="techText">{title}</p>
      <div className="techOutline">
        <p className="techInformation">{info}</p>
      </div>
    </div>
  );
};

export default InfoField;
