import React, { FunctionComponent, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./tableCard.scss";
import { Take } from "../../models/take";

export type Props = {
  data?: {
    uiInventoryItem: string;
    idTechnician: number;
    counter: number;
    sinternalReference: string;
    sreference: string;
    saircraftReg: string;
    slocation: string;
    sdescription: string;
    strigram: string;
    slastName: string;
    sfirstName: string;
  }[];
};

const TableCard: FunctionComponent<Props> = ({ data }) => {
  const convertData = () => {
    const usableData = [[""]];
    usableData.pop();

    data
      ? data.forEach((curTool) => {
          let temp: string[] = [];
          temp.push(curTool.sdescription);
          temp.push(curTool.strigram);
          temp.push(curTool.saircraftReg);
          temp.push(curTool.sreference);
          temp.push(curTool.slocation);
          temp.push(curTool.sinternalReference);
          if(curTool.sdescription.trim().length > 2){
            usableData.push(temp);
          }
        })
      : console.log();

    return usableData.map((row) => (
      <TableRow key={row.toString() + (Math.random() * 10).toString()}>
        <TableCell component="th" scope="row">
          {row[0]}
        </TableCell>
        <TableCell align="center">{row[1]}</TableCell>
        <TableCell align="center">{row[2]}</TableCell>
        <TableCell align="center">{row[3]}</TableCell>
        <TableCell align="center">{row[4]}</TableCell>
        <TableCell align="center">{row[5]}</TableCell>
      </TableRow>
    ));
  };

  return (
    <div className="tableCardContainer">
      <div className="tableCard">
        <TableContainer>
          <TableHead className="tableHead">
            <TableCell className="tableHeadText" align="center">
              Description
            </TableCell>
            <TableCell className="tableHeadText" align="center">
              Technician
            </TableCell>
            <TableCell className="tableHeadText" align="center">
              AircraftReg
            </TableCell>
            <TableCell className="tableHeadText" align="center">
              Work order
            </TableCell>
            <TableCell className="tableHeadText" align="center">
              Location
            </TableCell>
            <TableCell className="tableHeadText" align="center">
              JSA
            </TableCell>
          </TableHead>
          <TableBody>{data ? convertData() : undefined}</TableBody>
        </TableContainer>
      </div>
    </div>
  );
};

export default TableCard;
