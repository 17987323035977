import React, { createContext, useState, FC } from "react";
import { ContextState } from "./Types";
import axios from "axios";
import { Tool } from "../models/tool";
import { Technician } from "../models/technician";
import { Project } from "../models/project";
import { WorkOrder } from "../models/workorder";
import { Take } from "../models/take";
import { BugReport } from "../models/bugReport";
import { toolModel } from "../models/toolModel";
import { ToastContainer, toast } from "react-toastify";

const contextDefaultValues: ContextState = {
  fetchTool: () => {},
  setCurrentScannedTool: () => {},
  setMainTechnician: () => {},
  getCurrentTechnician: () => {},
  getAllTools: () => [],
  getMyTools: () => {},
  takeTool: () => {},
  fetchProjects: () => {},
  fetchOpenProjects: () => {},
  returnTool: () => {},
  clearTool: () => {},
  startWorkOrder: () => {},
  closeWorkOrder: () => {},
  fetchClosedTools: () => {},
  searchJSATool: () => {},
  setWorkOrder: (wo: WorkOrder) => {},
  setProjects: (projects: Project[]) => {},
  setJWTValue: (jwt: string) => {},
  sendBugReport: () => {},
  emitErrorToast: (message:string) => {},
  emitSuccessToast: (mesasge:string) => {},
  currentTechnician: {
    idTechnician: 0,
    uiTechnician: "",
    strigram: "",
    sfirstName: "",
    slastName: "",
    sprofession: "",
  },
  closedTools: [
    {
      uiInventoryItem: "",
      idTechnician: 0,
      counter: 0,
      sinternalReference: "",
      sreference: "",
      saircraftReg: "",
      slocation: "",
      sdescription: "",
      strigram: "",
      slastName: "",
      sfirstName: "",
    },
  ],
  myTools: [
    {
      uiInventoryItem: "",
      idTechnician: 0,
      counter: 0,
      sinternalReference: "",
      sreference: "",
      saircraftReg: "",
      slocation: "",
      sdescription: "",
      strigram: "",
      slastName: "",
      sfirstName: "",
    },
  ],
  allTools: [
    {
      uiInventoryItem: "",
      idTechnician: 0,
      counter: 0,
      sinternalReference: "",
      sreference: "",
      saircraftReg: "",
      slocation: "",
      sdescription: "",
      strigram: "",
      slastName: "",
      sfirstName: "",
    },
  ],
  mainTechnician: {
    idTechnician: 0,
    uiTechnician: "",
    strigram: "",
    sfirstName: "",
    slastName: "",
    sprofession: "",
  },
  currentScannedTool: {
    uiInventoryItem: "",
    sinternalReference: "",
    counter: 0,
    sstatus: "",
    sdescription: "",
    slocation: "",
  },
  projects: [],
  openProjects: [],
  currentWorkOrder: {
    idWorkOrder: 0,
    idTechnician: 0,
    openInToolingDb: false,
    numberOfToolsOpenOnWo: 0,
    sstatus: "",
    saircraftReg: "",
    sreference: "",
    closed: false,
  },
  counter: 0,
  JWT: '',
};

export const GlobalContext = createContext<ContextState>(contextDefaultValues);

const baseUrl = "https://tooling.jetsupport.nl";

const GlobalState: FC = ({ children }) => {
  const [currentScannedTool, setScannedTool] = useState({
    uiInventoryItem: "",
    sinternalReference: "",
    counter: 0,
    sstatus: "",
    sdescription: "",
    slocation: "",
  });
  const [mainTechnician, setMainScannedTechnician] = useState<Technician>();
  const [currentTechnician, setCurrentTechnician] = useState<Technician>({
    idTechnician: 0,
    uiTechnician: "",
    strigram: "",
    sfirstName: "",
    slastName: "",
    sprofession: "",
  });
  const [currentWorkOrder, setCurrentWorkOrder] = useState<WorkOrder>({
    idWorkOrder: 0,
    idTechnician: 0,
    openInToolingDb: false,
    numberOfToolsOpenOnWo: 0,
    sstatus: "",
    saircraftReg: "",
    sreference: "",
    closed: false,
  });
  const [projects, setProjects] = useState<Project[]>();
  const [openProjects, setOpenProjects] = useState<Project[]>();
  const [allTools, setAllTools] = useState([
    {
      uiInventoryItem: "",
      idTechnician: 0,
      counter: 0,
      sinternalReference: "",
      sreference: "",
      saircraftReg: "",
      slocation: "",
      sdescription: "",
      strigram: "",
      slastName: "",
      sfirstName: "",
    },
  ]);
  const [myTools, setMyTools] = useState([
    {
      uiInventoryItem: "",
      idTechnician: 0,
      counter: 0,
      sinternalReference: "",
      sreference: "",
      saircraftReg: "",
      slocation: "",
      sdescription: "",
      strigram: "",
      slastName: "",
      sfirstName: "",
    },
  ]);
  const [counter, setCounter] = useState(0);
  const [closedTools, setClosedTools] = useState([
    {
      uiInventoryItem: "",
      idTechnician: 0,
      counter: 0,
      sinternalReference: "",
      sreference: "",
      saircraftReg: "",
      slocation: "",
      sdescription: "",
      strigram: "",
      slastName: "",
      sfirstName: "",
    },
  ]);
  const [JWT, setJWT] = useState<string>("");

  const fetchClosedTools = (wo: string) => {
    axios
      .get<[toolModel]>(baseUrl + "/v0/tooling/all/" + wo, {
        headers: generateHeaders(),
      })
      .then((response) => {
        setClosedTools(response.data);
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  const takeTool = (tech: Technician, tool: Tool, wo: WorkOrder) => {
    axios
      .post(
        baseUrl + "/v0/tooling/take",
        {
          uiInventoryItem: tool.uiInventoryItem,
          idTechnician: tech.idTechnician,
          sstatus: tool.sstatus,
          sreference: wo.sreference,
          saircraftReg: wo.saircraftReg,
          sdescription: tool.sdescription,
          sinternalReference: tool.sinternalReference,
          slocation: tool.slocation,
          sfirstName: tech.sfirstName,
          strigram: tech.strigram,
          slastName: tech.slastName,
        },
        { headers: generateHeaders() }
      )
      .then((response) => {
        setScannedTool({
          uiInventoryItem: "",
          sinternalReference: "",
          counter: 0,
          sstatus: "",
          sdescription: "",
          slocation: "",
        });
        emitSuccessToast("Taken tool: " + tool.sdescription.toString());
        return response.data;
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  const startWorkOrder = (wo: WorkOrder) => {
    axios
      .post<WorkOrder>(
        baseUrl + "/v0/project/start",
        {
          idWorkOrder: wo.idWorkOrder,
          openInToolingDb: true,
          idTechnician: currentTechnician ? currentTechnician.idTechnician : "",
          sreference: wo.sreference,
          saircraftReg: wo.saircraftReg ? wo.saircraftReg : "Overig",
          sstatus: wo.sstatus,
        },
        { headers: generateHeaders() }
      )
      .then((response) => {
        emitSuccessToast("Started wo: " + wo.sreference.toString());
      })
      .catch((ex) => {
        emitErrorToast(
          "Something went wrong while starting WO: " + wo.sreference
        );
        console.log(ex);
      });
  };

  const closeWorkOrder = (wo: WorkOrder) => {
    axios
      .delete<WorkOrder>(
        baseUrl +
          "/v0/project/close/" +
          wo.sreference +
          "/" +
          currentTechnician.idTechnician, {
        headers: generateHeaders(),
      }
      )
      .then((response) => {
        emitSuccessToast("Closed wo: " + wo.sreference.toString());
      })
      .catch((ex) => {
        emitErrorToast(
          "Something went wrong while closing WO: " + wo.sreference
        );
        console.log(ex);
      });
  };

  const returnTool = (qr: string) => {
    axios
      .delete(baseUrl + "/v0/tooling/return/" + qr, {
        headers: generateHeaders(),
      })
      .then((response) => {
        setScannedTool({
          uiInventoryItem: "",
          sinternalReference: "",
          counter: 0,
          sstatus: "",
          sdescription: "",
          slocation: "",
        });
        emitSuccessToast("Returned tool");
      })
      .catch((ex) => console.log(ex));
    clearCurrentScannedTool();
  };

  const fetchProjects = () => {
    axios
      .get<Project[]>(baseUrl + "/v0/project/all", {
        headers: generateHeaders(),
      })
      .then((response) => {
        setProjects(response.data);
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  const fetchOpenProjects = () => {
    axios
      .get<Project[]>(baseUrl + "/v0/project/active", {
        headers: generateHeaders(),
      })
      .then((response) => {
        setOpenProjects(response.data);
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  const setWorkOrder = (wo: WorkOrder) => {
    setCurrentWorkOrder(wo);
  };

  const fetchTool = (qr: string) => {
    axios
      .get<Tool>(baseUrl + "/v0/tooling/" + qr, {
        headers: generateHeaders(),
      })
      .then((response) => {
        if(response.data){
          setScannedTool(response.data);
          setCounter(response.data.counter);
        } else {
          emitErrorToast('Error while scanning tool, you might have accidentally scanned a technician instead');
        }
        
      })
      .catch((ex) => {
        emitErrorToast("Something went wrong while fetching tool");
        console.log(ex);
      });
  };

  const searchJSATool = (jsa: string) => {
    axios
      .get<Tool>(baseUrl + "/v0/tooling/jsa/" + jsa.toUpperCase(), {
        headers: generateHeaders(),
      })
      .then((response) => {
        emitSuccessToast("Found tool: " + response.data.sdescription);
        setScannedTool(response.data);
      })
      .catch((ex) => {
        emitErrorToast("No tool found with JSA: " + jsa);
        console.log(ex);
      });
  };

  const emitSuccessToast = (title: string) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const emitErrorToast = (title: string) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const clearCurrentScannedTool = () => {};

  const setCurrentScannedTool = (qr: string) => {
    //setScannedTool(qr); //TODO: fetch tool
  };

  const setMainTechnician = (qr: string) => {
    axios
      .get<Technician>(baseUrl + "/v0/employee/" + qr.toUpperCase(), {
        headers: generateHeaders(),
      })
      .then((response) => {
        setMainScannedTechnician(response.data);
        //storeData("@main_Tech", response.data);
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  const getCurrentTechnician = (qr: string) => {
    axios
      .get<Technician>(baseUrl + "/v0/employee/" + qr.toUpperCase(), {
        headers: {
          Authorization: `Bearer ${JWT}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if(response.data){
          setCurrentTechnician(response.data);
        } else {
          emitErrorToast('Error while scanning technician, you might have accidentally scanned a tool instead');
        }
        
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  const getAllTools = () => {
    axios
      .get<[toolModel]>(baseUrl + "/v0/tooling/all", {
        headers: generateHeaders(),
      })
      .then((response) => {
        setAllTools(response.data);
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  const getMyTools = (qr: string) => {
    axios
      .get<[toolModel]>(baseUrl + "/v0/employee/myTools/" + qr, {
        headers: generateHeaders(),
      })
      .then((response) => {
        setMyTools(response.data);
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  const sendBugReport = (bugReport: BugReport) => {
    axios
      .post<BugReport>(
        baseUrl + "/v0/feedback",
        {
          technician: bugReport.technician,
          message: bugReport.message,
          sreference: bugReport.sReference,
        },
        { headers: generateHeaders() }
      )
      .then(() => {
        emitSuccessToast("Bug report successfully send!");
      })
      .catch((ex) => {
        console.log(ex);
        emitErrorToast("Something went wrong while sending bug report");
      });
  };

  const generateHeaders = () => {
    const headers = new Headers();
    const bearer = `Bearer ${JWT}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    if (!JWT) {
      emitErrorToast("Please close the site and open it again to login");
      return;
    }
    return {
      Authorization: bearer,
      "Content-Type": "application/json",
    };
  };

  const setJWTValue = (jwt: string) => {
    setJWT(jwt);
    emitSuccessToast("Logged in");
  };

  const clearTool = () => {
    setScannedTool({
      uiInventoryItem: "",
      sinternalReference: "",
      counter: 0,
      sstatus: "",
      sdescription: "",
      slocation: "",
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        fetchTool,
        setCurrentScannedTool,
        currentScannedTool,
        setMainTechnician,
        mainTechnician,
        getCurrentTechnician,
        currentTechnician,
        getAllTools,
        allTools,
        getMyTools,
        myTools,
        takeTool,
        projects,
        fetchProjects,
        currentWorkOrder,
        setWorkOrder,
        setProjects,
        returnTool,
        fetchOpenProjects,
        openProjects,
        startWorkOrder,
        closeWorkOrder,
        closedTools,
        fetchClosedTools,
        searchJSATool,
        sendBugReport,
        counter,
        setJWTValue,
        JWT,
        clearTool,
        emitSuccessToast,
        emitErrorToast,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
