import React, { FunctionComponent, useEffect, useState } from "react";

import "./popUp.scss";

export type Props = {
  title: string;
  visible: boolean;
  setVisible: Function;
};

const PopUp: FunctionComponent<Props> = ({ title, children, visible, setVisible }) => {

  return (
    <div style={visible ? { display: "block" } : { display: "none" }}>
      <div className="popUpBackground" onClick={() => setVisible()}>
        <div className="popUpContainer">
          <div className="popUpHeader">
            <p className="popUpHeaderText">{title}</p>
          </div>
          <div className="popUpContent">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
