import React, { FunctionComponent, useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../contexts/GlobalProvider";
import "./header.scss";

type Props = {
  title: string;
  customFunction?: Function;
};

export const PageHeader: FunctionComponent<Props> = ({ title, customFunction }: Props) => {
  const { clearTool } = useContext(GlobalContext);
  
  const history = useHistory();
  const navigateBack = () => {
    history.goBack();
    if(customFunction) {
      clearTool();
    }
  };

  return (
    <div className="headerContainer">
      <div className="headerRow">
        <div className="circle" onClick={navigateBack}>
          <p className="circleText">&#5193;</p>
        </div>
        <p className="headerText">{title}</p>
        {customFunction ? (<div className="circle" onClick={() => customFunction()}>
          <p className="circleText">&#128270;</p>
        </div>) : null}
        
      </div>
    </div>
  );
};

export default PageHeader;
