import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
} from "react";
import QrReader from "react-qr-reader";
import { GlobalContext } from "../../../contexts/GlobalProvider";
import ButtonLayout from "../../buttonLayout/buttonLayout";
import { PageHeader } from "../../header/pageHeader";
import PopUp from "../../popUp/popUp";
import TechnicianCard from "../../TechnicianCard/technicianCard";
import ToolCard from "../../ToolCard/toolCard";
import "./returnToolPage.scss";

const ReturnToolPage: FunctionComponent = () => {
  const {
    fetchTool,
    getCurrentTechnician,
    currentScannedTool,
    currentTechnician,
    allTools,
    clearTool,
    emitErrorToast,
    returnTool,
  } = useContext(GlobalContext);
  const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(false);
  const [toolScannerOpen, setToolScannerOpen] = useState(true);

  const scanTool = (qr?: string) => {
    if (qr) {
      fetchTool(qr.toUpperCase());
      setToolScannerOpen(false);
    }
  };

  const toggleToolScannerOpen = () => {
    setToolScannerOpen(!toolScannerOpen);
  };

  useEffect(() => {
    if (currentScannedTool.uiInventoryItem.length > 2) {
      let found = false;
      
      allTools.forEach((element => {
        if(found){
          setConfirmButtonEnabled(true);
          return;
        }

        found = element.uiInventoryItem === currentScannedTool.uiInventoryItem;
      }));

      if(found){
        setConfirmButtonEnabled(true);
        return;
      } else {
        emitErrorToast("The tool has not been taken so it can't be returned");
        setConfirmButtonEnabled(false);
        clearTool();
      }
    };
  }, [currentScannedTool]);

  const returnCurrentTool = () => {
    if(currentScannedTool.uiInventoryItem.length > 1){
      returnTool(currentScannedTool.uiInventoryItem);
    }
    setToolScannerOpen(true);
  };

  return (
    <div className="returnToolPageContainer">
      <PageHeader title="Return tool" />
      {toolScannerOpen ? (
        <PopUp
          title="Scan a tool"
          visible={toolScannerOpen}
          setVisible={() => toggleToolScannerOpen()}
        >
          <QrReader
            delay={1000}
            onError={() => console.log("scanner error")}
            onScan={(data) => scanTool(data?.toString())}
            style={{ width: "60%" }}
          />
        </PopUp>
      ) : null}
      <div className="returnToolContent">
        <ToolCard tool={currentScannedTool} />
      </div>
      <ButtonLayout
        confirmEnabled={confirmButtonEnabled}
        scanTech={false}
        scanToolFunction={() => toggleToolScannerOpen()}
        confirmFunction={() => returnCurrentTool()}
      />
    </div>
  );
};

export default ReturnToolPage;
