/* eslint no-use-before-define: 0 */
import React, { FunctionComponent, useState, useContext } from "react";
import InfoField from "../infoField/infoField";
import { Tool } from "../../models/tool";
import "./toolCard.scss";
import PopUp from "../popUp/popUp";
import { GlobalContext } from "../../contexts/GlobalProvider";

export type Props = {
  tool?: Tool;
  customTool?: boolean;
};

const ToolCard: FunctionComponent<Props> = ({ tool, customTool }) => {
  const { searchJSATool, currentScannedTool } = useContext(GlobalContext);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [JSANumber, setJSANumber] = useState("");

  const togglePopUpVisible = () => {
    setPopUpVisible(!popUpVisible);
  };

  const test = () => {};

  const search = () => {
    searchJSATool(JSANumber.toUpperCase());
  };

  return (
    <div className="technicianCardContainer maxHeight">
      <div className="toolCardHeader">
        <div className="toolLeft" />
        <p className="toolCardHeaderText">Tool</p>
        <div className="toolCircle" onClick={togglePopUpVisible}>
          <p className="toolCircleText">&#9881;</p>
        </div>
      </div>
      <PopUp
        title="Custom tool"
        visible={popUpVisible}
        setVisible={!popUpVisible ? () => togglePopUpVisible() : () => test()}
      >
        <p className="searchText">JSA</p>
        <input
          className="searchInputTool"
          type="text"
          value={JSANumber}
          onChange={(event) => setJSANumber(event.target.value)}
        />
        {currentScannedTool ? (
          <div className="toolRow">
            <p>Tool found: </p>
            <p>{currentScannedTool.sdescription}</p>
          </div>
        ) : null}
        <div className="toolRow">
          <div
            className="layoutButton layoutCancelButton"
            onClick={() => togglePopUpVisible()}
          >
            <p className="layoutButtonText">Close</p>
          </div>
          <div
            className={
              JSANumber.length > 3
                ? "layoutConfirmButton layoutButton"
                : "layoutDisabledButton layoutButton"
            }
            onClick={() => search()}
          >
            <p className="layoutButtonText">Search</p>
          </div>
          <div
            className={
              JSANumber.length > 3
                ? "layoutConfirmButton layoutButton"
                : "layoutDisabledButton layoutButton"
            }
            onClick={() => togglePopUpVisible()}
          >
            <p className="layoutButtonText">Confirm</p>
          </div>
        </div>
      </PopUp>
      <InfoField title="Description" info={tool ? tool.sdescription : ""} />
      <InfoField
        title="Internal reference"
        info={tool ? tool.sinternalReference : ""}
      />
      <InfoField title="Inventory location" info={tool ? tool.slocation : ""} />
    </div>
  );
};

export default ToolCard;
