import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import { GlobalContext } from "../../../contexts/GlobalProvider";
import { PageHeader } from "../../header/pageHeader";
import SearchBar from "../../searchBar/searchBar";
import TableCard from "../../tableCard/tableCard";
import TechnicianCard from "../../TechnicianCard/technicianCard";
import PopUp from "../../popUp/popUp";
import QrReader from "react-qr-reader";
import "./myToolsPage.scss";

const MyToolsPage: FunctionComponent = () => {
  const { setMainTechnician, mainTechnician, getMyTools, myTools } =
    useContext(GlobalContext);
  const [techScannerOpen, setTechScannerOpen] = useState(false);

  const scanTech = (qr?: string) => {
    if (qr) {
      setMainTechnician(qr.toUpperCase());
      setTechScannerOpen(false);
    }
  };
  const toggleTechScannerOpen = () => {
    setTechScannerOpen(!techScannerOpen);
  };

  useEffect(() => {
    if (mainTechnician) {
      getMyTools(mainTechnician.uiTechnician);
    }
  }, [mainTechnician]);

  return (
    <div className="myToolPageContainer">
      <PageHeader title="My tools" />
      {techScannerOpen ? (
        <PopUp
          title="Scan a crew card"
          visible={techScannerOpen}
          setVisible={() => toggleTechScannerOpen()}
        >
          <QrReader
            delay={1000}
            onError={() => console.log("scanner error")}
            onScan={(data) => scanTech(data?.toString())}
            style={{ width: "60%" }}
          />
        </PopUp>
      ) : null}
      <div className="myToolsRow">
        <TechnicianCard technician={mainTechnician} />

        {<TableCard data={myTools} />}
      </div>
      <div className="myToolButton" onClick={() => toggleTechScannerOpen()}>
        <p className="myToolButtonText">Scan tech</p>
      </div>
    </div>
  );
};

export default MyToolsPage;
