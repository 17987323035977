import React, { useState, useContext } from "react";
import "./App.scss";
import HomePage from "./components/pages/home/homePage";
import TakeToolPage from "./components/pages/takeTool/takeToolPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReturnToolPage from "./components/pages/returnTool/returnToolPage";
import SearchToolPage from "./components/pages/searchTool/searchToolPage";
import ProjectPage from "./components/pages/projects/projectPage";
import MyToolsPage from "./components/pages/myTools/myToolsPage";
import ReportBugPage from "./components/pages/reportBug/reportBugPage";
import GlobalState, { GlobalContext } from "./contexts/GlobalProvider";
import { ToastContainer } from "react-toastify";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  MsalProvider,
} from "@azure/msal-react";
import { loginRequest, BFFRequest, msalConfig, BFFConfig } from "./authConfig";
import { callMsGraph } from "./graph";
import { PublicClientApplication } from "@azure/msal-browser";
import "react-toastify/dist/ReactToastify.css";

const pca = new PublicClientApplication(msalConfig);

function App() {
  return (
    <MsalProvider instance={pca}>
      <MainContent />
    </MsalProvider>
  );
}

export default App;

const MainContent = () => {
  return (
    <div className="app">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="cardContainer card">
        <GlobalState>
          <AuthenticatedTemplate>
            <Router>
              <Switch>
                <Route path="/taketool" component={TakeToolPage}></Route>
                <Route path="/returntool" component={ReturnToolPage}></Route>
                <Route path="/searchtool" component={SearchToolPage}></Route>
                <Route path="/projects" component={ProjectPage}></Route>
                <Route path="/mytools" component={MyToolsPage}></Route>
                <Route path="/reportbug" component={ReportBugPage}></Route>
                <Route path="/" component={HomePage} />
                <Route path="*" component={HomePage} />
              </Switch>
            </Router>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <SignInButton />
          </UnauthenticatedTemplate>
        </GlobalState>
      </div>
    </div>
  );
};

const SignInButton = () => {
  const { instance } = useMsal();
  const { setJWTValue } = useContext(GlobalContext);

  const handleLogin = () => {
    instance
      .loginPopup(BFFRequest)
      .then((response) => {
        setJWTValue(response.accessToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className="signInContainer">
      <div
        className="layoutConfirmButton signInButton"
        onClick={() => handleLogin()}
      >
        <p className="layoutButtonText">Click here to log in using Microsoft</p>
      </div>
    </div>
  );
};
