import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import { GlobalContext } from "../../../contexts/GlobalProvider";
import ButtonLayout from "../../buttonLayout/buttonLayout";
import { PageHeader } from "../../header/pageHeader";
import InputField from "../../inputField/inputField";
import TechnicianCard from "../../TechnicianCard/technicianCard";
import PopUp from "../../popUp/popUp";
import QrReader from "react-qr-reader";
import "./reportBugPage.scss";
import { BugReport } from "../../../models/bugReport";
import { useHistory } from "react-router-dom";

const ReportBugPage: FunctionComponent = () => {
  const history = useHistory();
  const { setMainTechnician, mainTechnician, sendBugReport } =
    useContext(GlobalContext);
  const [woNumber, setWoNumber] = useState("");
  const [message, setMessage] = useState("");
  const [techScannerOpen, setTechScannerOpen] = useState(false);
  const [confirmEnabled, setConfirmEnabled] = useState(false);

  const scanTech = (qr?: string) => {
    if (qr) {
      setMainTechnician(qr.toUpperCase());
      setTechScannerOpen(false);
    }
  };
  const toggleTechScannerOpen = () => {
    setTechScannerOpen(!techScannerOpen);
  };

  const sendReport = () => {
    let bug: BugReport = {
      message: message,
      sReference: woNumber,
      technician: mainTechnician
        ? mainTechnician.sfirstName + " " + mainTechnician.slastName
        : "",
    };
    sendBugReport(bug);
    history.goBack();
  };

  useEffect(() => {
    if (message && woNumber && mainTechnician) {
      setConfirmEnabled(true);
    } else {
      setConfirmEnabled(false);
    }
  }, [message, woNumber, mainTechnician]);

  return (
    <div className="reportBugPageContainer">
      <PageHeader title="Report bug" />
      {techScannerOpen ? (
        <PopUp
          title="Scan a crew card"
          visible={techScannerOpen}
          setVisible={() => toggleTechScannerOpen()}
        >
          <QrReader
            delay={1000}
            onError={() => console.log("scanner error")}
            onScan={(data) => scanTech(data?.toString())}
            style={{ width: "60%" }}
          />
        </PopUp>
      ) : null}
      <div className="reportBugContent">
        <div className="reportBugCard">
          <div className="reportBugCardHeader">
            <p className="reportBugCardHeaderText">Bug</p>
          </div>
          <InputField
            title="Workorder number"
            info={woNumber}
            setInfo={setWoNumber}
          />
          <InputField title="Message" info={message} setInfo={setMessage} />
        </div>
        <TechnicianCard technician={mainTechnician} />
      </div>
      <ButtonLayout
        scanTool={true}
        scanTech={true}
        confirmEnabled={confirmEnabled}
        scanTechFunction={() => toggleTechScannerOpen()}
        confirmFunction={() => sendReport()}
      />
    </div>
  );
};

export default ReportBugPage;
