import React, { FunctionComponent, useState, useEffect } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./searchBar.scss";

export type Props = {
  searchTerm: string;
  setSearchTerm: Function;
};

const SearchBar: FunctionComponent<Props> = ({ searchTerm, setSearchTerm }) => {
  return (
    <div className="searchBarContainer">
      <div className="searchCard">
        <p className="searchText">Search</p>
        <input
          className="searchInput"
          type="text"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
      </div>
    </div>
  );
};

export default SearchBar;
