import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
} from "react";
import { GlobalContext } from "../../../contexts/GlobalProvider";
import { Project } from "../../../models/project";
import { WorkOrder } from "../../../models/workorder";
import CollapsibleCard from "../../collapsibleCard/collapsibleCard";
import { PageHeader } from "../../header/pageHeader";
import SearchBar from "../../searchBar/searchBar";
import WOItem from "../../woItem/woItem";
import "./projectPage.scss";

const ProjectPage: FunctionComponent = () => {
  const { fetchProjects, projects, allTools } = useContext(GlobalContext);
  const [masterDataSource, setMasterDataSource] = useState(projects);
  const [filteredDataSource, setFilteredDataSource] =
    useState(masterDataSource);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!projects) {
      fetchProjects();
    }
    setMasterDataSource(projects);
  }, [projects]);

  const filterData = (_term: string) => {
    setSearchTerm(_term);
    const term = _term.toLowerCase();
    if (!term || !term.trim()) {
      setFilteredDataSource(projects);
    }

    let filtered: Project[] = [];

    projects
      ? projects.forEach((project: Project) => {
          if (
            !project ||
            !project.saircrafType ||
            !project.saircraftReg ||
            !project.workOrderList
          )
            return;
          if (
            project.saircraftReg.toLowerCase().match(term) ||
            project.saircrafType.toLowerCase().match(term)
          ) {
            if (!filtered.includes(project)) {
              filtered.push(project);
            }
          }
          project.workOrderList.forEach((wo: WorkOrder) => {
            if (wo.sreference.toLowerCase().match(term)) {
              if (!filtered.includes(project)) {
                filtered.push(project);
              }
            }
          });
        })
      : console.log();
    filtered
      ? setFilteredDataSource(filtered)
      : setFilteredDataSource(masterDataSource);
  };

  const generateProjectCards = () => {
    return (
      <div>
        {filteredDataSource?.map((project) => (
          <CollapsibleCard title={project.saircraftReg}>
            {generateWorkOrderCard(project.workOrderList)}
          </CollapsibleCard>
        ))}
      </div>
    );
  };

  const checkClear = (wo: WorkOrder) => {
    if(!allTools) return true
    let found = false;
    allTools.forEach(tool => {
      if(tool.saircraftReg == wo.saircraftReg){
        found = true;
        return false;
      }
    })

    if(!found){
      return true;
    }
  }

  const generateWorkOrderCard = (workOrders: WorkOrder[]) => {
    return (
      <div>
        {workOrders.map((workOrder: WorkOrder) => (
          <WOItem
            title={workOrder.sreference}
            wo={workOrder}
            open={workOrder.openInToolingDb}
            clear={checkClear(workOrder)}
            closed={workOrder.closed}
            circle={true}
          />
        ))}
      </div>
    );
  };
  return (
    <div className="projectPageContainer">
      <PageHeader title="Projects" />
      <SearchBar searchTerm={searchTerm} setSearchTerm={filterData} />

      {projects ? generateProjectCards() : <p>No projects</p>}
    </div>
  );
};

export default ProjectPage;
