import { Divider } from "@material-ui/core";
import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import { GlobalContext } from "../../contexts/GlobalProvider";
import { Take } from "../../models/take";
import { Tool } from "../../models/tool";
import { toolModel } from "../../models/toolModel";
import { WorkOrder } from "../../models/workorder";
import ButtonLayout from "../buttonLayout/buttonLayout";
import PopUp from "../popUp/popUp";
import TableCard from "../tableCard/tableCard";
import TechnicianCard from "../TechnicianCard/technicianCard";
import QrReader from "react-qr-reader";
import "./woItem.scss";

export type Props = {
  title: string;
  wo: WorkOrder;
  clear?: Boolean;
  open?: Boolean;
  closed?: Boolean;
  circle: Boolean;
};

const WOItem: FunctionComponent<Props> = ({
  title,
  wo,
  clear,
  open,
  closed,
}) => {
  const {
    currentTechnician,
    getCurrentTechnician,
    startWorkOrder,
    closeWorkOrder,
    fetchProjects,
    fetchClosedTools,
    closedTools,
    projects,
    emitErrorToast,
  } = useContext(GlobalContext);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [techScannerOpen, setTechScannerOpen] = useState(false);

  const scanTech = (qr?: string) => {
    if (qr) {
      getCurrentTechnician(qr.toUpperCase());
      setTechScannerOpen(false);
    }
  };

  const toggleTechScannerOpen = () => {
    setTechScannerOpen(!techScannerOpen);
  };

  const onWOClick = () => {
    togglePopUpVisible();
  };

  const test = () => {};

  const togglePopUpVisible = () => {
    fetchClosedTools(wo.sreference);
    setPopUpVisible(!popUpVisible);
  };

  const openWO = () => {
    if(!wo.closed){
      startWorkOrder(wo);
    }
    fetchProjects();
    if (!wo.closed){
      if (projects) {
        projects.forEach((project) => {
          project.workOrderList.forEach((WO) => {
            if (WO.idWorkOrder === wo.idWorkOrder) {
              wo.openInToolingDb = true;
            }
          });
        });
      }
    }
    if(wo.closed){
      emitErrorToast('This WorkOrder has already been closed');
    }
    togglePopUpVisible();
  };

  const closeWO = () => {
    if (currentTechnician && closedTools.length === 0) {
      closeWorkOrder(wo);
      fetchProjects();
      if (projects) {
        projects.forEach((project) => {
          project.workOrderList.forEach((WO) => {
            if (WO.idWorkOrder === wo.idWorkOrder) {
              wo.openInToolingDb = false;
              wo.closed = true;
            }
          });
        });
      }
      togglePopUpVisible();
    }
  };
  const generateClosedTools = () => {
    return (
      <div>
        <TableCard data={closedTools} />
      </div>
    );
  };

  const generateOpenCircle = () => {
    return (
      <div>
        {clear ? (
          <div className="WOLeftClear" />
        ) : (
          <div className="WOLeftNotClear" />
        )}
      </div>
    );
  };
  return (
    <div
      className="collapsibleCardTriggerHeader"
      onClick={popUpVisible ? undefined : () => onWOClick()}
    >
      {open ? (
        generateOpenCircle()
      ) : closed ? (
        <div className="WOLeftClosed" />
      ) : (
        <div className="WOLeft" />
      )}
      <p className="woText">{title}</p>
      <div className="collapsibleCardCircleWO">
        <p className="collapsibleCardCircleTextWO">&#8250;</p>
      </div>
      <Divider />

      <PopUp
        title={title}
        visible={popUpVisible}
        setVisible={popUpVisible ? () => test() : () => togglePopUpVisible()}
      >
        {techScannerOpen ? (
          <PopUp
            title="Scan a crew card"
            visible={techScannerOpen}
            setVisible={() => toggleTechScannerOpen()}
          >
            <QrReader
              delay={1000}
              onError={() => console.log("scanner error")}
              onScan={(data) => scanTech(data?.toString())}
              style={{ width: "60%" }}
            />
          </PopUp>
        ) : null}
        {closedTools.length > 0 ? (
          <div>
            <p className="redText">Not all tools have been returned</p>
            {generateClosedTools()}
          </div>
        ) : null}
        {closed ? (
          <div>
            <p className="redText">Project has already been closed</p>
          </div>
        ) : null}
        <TechnicianCard technician={currentTechnician} />
        <div className="buttonLayoutRow">
          <div
            className={"layoutButton layoutCancelButton"}
            onClick={() => onWOClick()}
          >
            <p className="layoutButtonText">Close</p>
          </div>
          <div className="buttonLayoutRowMiddle">
            <div
              className="layoutButton layoutNormalButton"
              onClick={() => toggleTechScannerOpen()}
            >
              <p className="layoutButtonText">Scan tech</p>
            </div>
          </div>
          {wo.openInToolingDb ? (
            <div
              className={
                currentTechnician.sfirstName.length > 1 && closedTools.length === 0
                  ? "layoutConfirmButton layoutButton"
                  : "layoutDisabledButton layoutButton"
              }
              onClick={() => closeWO()}
            >
              <p className="layoutButtonText">Close project</p>
            </div>
          ) : (
            <div
              className={
                currentTechnician.sfirstName.length > 1
                  ? "layoutConfirmButton layoutButton"
                  : "layoutDisabledButton layoutButton"
              }
              onClick={() => openWO()}
            >
              <p className="layoutButtonText">Open</p>
            </div>
          )}
        </div>
      </PopUp>
    </div>
  );
};

export default WOItem;
