import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from "react";
import QrReader from "react-qr-reader";
import { GlobalContext } from "../../../contexts/GlobalProvider";
import { Take } from "../../../models/take";
import { Tool } from "../../../models/tool";
import { toolModel } from "../../../models/toolModel";
import { PageHeader } from "../../header/pageHeader";
import PopUp from "../../popUp/popUp";
import SearchBar from "../../searchBar/searchBar";
import TableCard from "../../tableCard/tableCard";
import "./searchToolPage.scss";

const SearchToolPage: FunctionComponent = () => {
  const {
    getAllTools,
    allTools,
    clearTool,
    currentScannedTool,
    emitErrorToast,
    fetchTool,
  } = useContext(GlobalContext);
  const [masterDataSource, setMasterDataSource] = useState(allTools);
  const [filteredDataSource, setFilteredDataSource] =
    useState(masterDataSource);
  const [searchTerm, setSearchTerm] = useState("");
  const [custom, setCustom] = useState(false);
  const [customSearch, setCustomSearch] = useState(false);

  useEffect(() => {
    getAllTools();
    setMasterDataSource(allTools);
  }, []);

  const filterData = (_term: string) => {
    setSearchTerm(_term);
    const term = _term.toLowerCase();
    if (!term || !term.trim()) {
      setFilteredDataSource(masterDataSource);
    }

    const filtered: toolModel[] =[];
    masterDataSource
      ? masterDataSource.forEach((take) => {
          if (!take) return;
          if (
            take.sdescription.toLowerCase().match(term) ||
            take.strigram.toLowerCase().match(term) ||
            take.sinternalReference.toLowerCase().match(term) ||
            take.slocation.toLowerCase().match(term) ||
            take.uiInventoryItem.toLowerCase().match(term)
          ) {
            if (!filtered.includes(take) && take.sdescription.trim().length > 2) {
              filtered.push(take);
            }
          }
        })
      : console.log();
      filtered.filter(n => n);
    setFilteredDataSource(filtered);
  };

  const customFunction = () => {
    clearTool();
    setCustomSearch(true);
    setCustom(!custom);
  };

  useEffect(() => {
    let found = false;
    if (allTools && custom && currentScannedTool.sdescription.length > 2) {
      allTools.forEach((element) => {
        if (element.uiInventoryItem === currentScannedTool.uiInventoryItem) {
          filterData(element.sdescription);
          found = true;
          clearTool();
          return;
        }
      });
    }
    if (!found && custom && currentScannedTool.sdescription.length > 2) {
      emitErrorToast("Tool has not been taken, please try another QR code");
    }
  }, [currentScannedTool]);

  const toggleCustom = () => {
    setCustomSearch(!customSearch);
  };

  const scanTool = (qr?: String) => {
    if(qr){
      fetchTool(qr.toUpperCase());
      setCustomSearch(false);
    }
  };

  return (
    <div className="myToolPageContainer">
      <PageHeader title="Search tool" customFunction={() => customFunction()} />
      <SearchBar searchTerm={searchTerm} setSearchTerm={filterData} />

      <PopUp
        title="Scan a tool"
        visible={customSearch}
        setVisible={() => toggleCustom()}
      >
        <QrReader
          delay={0}
          onError={() => console.log("scanner error")}
          onScan={(data) => scanTool(data?.toString())}
          style={{ width: "60%" }}
        />
      </PopUp>

      <TableCard data={filteredDataSource} />
    </div>
  );
};

export default SearchToolPage;
