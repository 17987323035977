import React, { FunctionComponent, useEffect } from "react";
import "./inputField.scss";

type Props = {
  title: any;
  info: any;
  setInfo: any;
};

const InputField: FunctionComponent<Props> = ({ title, info, setInfo }) => {
  return (
    <div className="inputFieldContainer">
      <p className="inputText">{title}</p>
      <textarea
        className="inputOutline"
        value={info}
        onChange={(event) => setInfo(event.target.value)}
      />
    </div>
  );
};

export default InputField;
