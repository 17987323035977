import React, { FunctionComponent, useState } from "react";
import Collapse from "@kunukn/react-collapse";
import "./collapsibleCard.scss";

export type Props = {
  title: string;
};

const CollapsibleCard: FunctionComponent<Props> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);

  const onWOClick = () => {
    togglePopUpVisible();
  };

  const togglePopUpVisible = () => {
    setPopUpVisible(!popUpVisible);
  };

  return (
    <div className="collapsibleCardContainer">
      <div className="collapsibleCard">
        <div
          className="collapsibleCardTriggerHeader"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="collapsibleCardTriggerHeader">
            <div className="left" />
            <p className="collapsibleCardTriggerHeaderText">{title}</p>
            <div className="collapsibleCardCircle">
              <p className="collapsibleCardCircleText">&#5193;</p>
            </div>
          </div>
        </div>
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </div>
    </div>
  );
};

export default CollapsibleCard;
